import type { MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { useContext } from 'react'
import {
  cleanPage,
  fetchPage,
  PageViewer,
  ReactBricksContext,
  useReactBricksContext
} from 'react-bricks/frontend'

import { SomethingWrong } from '~/components/error'
import Layout from '~/components/Layout'
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '~/constants'

export const loader = async () => {
  const SLUG = 'home'
  const apiKey = (process.env.REACT_BRICKS_API_KEY as string) || ''

  try {
    const page = await fetchPage(SLUG, apiKey)
    return { page }
  } catch {
    throw new Error(
      `Cannot find the "${SLUG}" page. Make sure there is a Page with handle ${SLUG} in Admin`
    )
  }
}

export const meta: MetaFunction = () => ({
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION
})

const Page = () => {
  const { page } = useLoaderData()
  const { pageTypes, bricks } = useReactBricksContext()

  const pageOk = page ? cleanPage(page, pageTypes, bricks) : null

  return (
    <Layout>
      <PageViewer page={pageOk} />
    </Layout>
  )
}

export function ErrorBoundary({ error }: { error: Error }) {
  return <SomethingWrong error={error} />
}

export default Page
